<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg9 xl7>
        <v-data-table
          dense
          :headers="headers"
          :items="compPFisicasLista"
          class="elevation-10"
          :search="search"
          :loading="!compPFisicasProgress"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          sortBy="id"
          sort-desc
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-account-box</v-icon>
              <v-toolbar-title>Pessoas Físicas</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSelecionarPFisicaEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Pessoa Física</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="auto" md="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="ml-2" align-self="end">
                  <v-chip-group mandatory active-class="primary" show-arrows>
                    <v-chip @click="listaPFisPFisicas = 'pfisicas'">
                      Pessoas Físicas
                    </v-chip>
                    <v-chip @click="listaPFisPFisicas = 'lixeira'">
                      Lixeira
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  v-if="metEdicaoLixeira()"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarPFisicaEdicao(item)"
                  color="blue darken-1"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  :color="metLixeiraColor(item.lixeira)"
                  v-bind="attrs"
                  v-on="on"
                  @click="metParaLixeira(item)"
                >
                  {{ item.lixeira ? "mdi-recycle" : "mdi-trash-can" }}
                </v-icon>
              </template>
              <span>{{ item.lixeira ? "Reciclar" : "Lixeira" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import DatasMixin from "@/mixins/DatasMixin";
// import moment from "moment";

const { mapState, mapActions, mapGetters } =
  createNamespacedHelpers("finPFisicas");

export default {
  mixins: [DatasMixin],
  name: "FinPFisicasLista",
  data: () => ({
    listaPFisPFisicas: "pfisicas",
    search: "",
    headers: [
      { text: "Código", value: "id", align: "start" },
      {
        text: "Pessoa",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    // perm: [
    //   {
    //     field: "Pensil",
    //     subject: "action",
    //     component: "LojaClientesComprasLista",
    //     group: [5],
    //   },
    // ],
  }),

  computed: {
    ...mapState(["staErro", "staPFisicas", "staPFisicaSelecionado"]),
    ...mapGetters(["getPFisPFisicas", "getPFisLixeira"]),

    compPFisicasLista() {
      return this.listaPFisPFisicas == "pfisicas"
        ? this.getPFisPFisicas
        : this.getPFisLixeira;
      // return this.$store.state.finPFisicas.staPFisicas;
    },
    compPFisicasProgress() {
      // console.log(!this.$store.state.finPFisicas.staPFisicas.length);
      return this.$store.state.finPFisicas.staPFisicas;
    },
  },

  created() {
    register(this.$store);
    this.actListarPFisicas(this.$store.state.login.licenca);
  },

  watch: {
    erro() {
      if (this.staErro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.staErro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  mounted() {
    //this.obterPFisicas();
  },

  methods: {
    ...mapActions([
      "actListarPFisicas",
      "actSelecionarPFisica",
      "actResetarPFisica",
      "actLixeiraPFisica",
    ]),

    metPermissao(field) {
      if (this.perm.some((t) => t.field == field)) {
        const permObject = this.perm.filter((t) => t.field == field);
        if (permObject[0].group[0] == this.$store.state.login.usuario.grupo) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    /* initialize() {
      // if (this.pfisicas === null) {
      //   this.pfisicas = null;
      // }
    },
    login() {
      this.$router.push({ name: "Login" });
    },

     formataData(data) {
      return moment(data).utc().format("DD-MM-YYYY");
    },
    formataDataIn(data) {
      return moment(data).utc().format("YYYY-MM-DD");
    },

    novoPFisica() {
      if (this.staPFisicaSelecionado) {
        this.resetarPFisicaSelecionado();
        return;
      }
    },

    paraAtivacao(pfisica) {
      // if (pfisica.data_cadastro != "0000-00-00") {
      pfisica.data_cadastro = this.formataDataIn(pfisica.data_cadastro);
      // }
      // if (pfisica.ultima_compra != "0000-00-00") {
      pfisica.ultima_compra = this.formataDataIn(pfisica.ultima_compra);
      // }
      this.ativarPFisica({ pfisica });
      // this.actListarPFisicas();
    },
    resetarPFisica() {
      this.resetarPFisicaSelecionado();
    }, */

    async metSelecionarPFisicaEdicao(pfisica) {
      if (pfisica != undefined) {
        await this.actSelecionarPFisica({
          id: pfisica.id,
          licenca: this.$store.state.login.licenca,
        });
      } else {
        this.actResetarPFisica();
      }
      await this.$router.push({ name: "FinPFisicasForm" });
    },

    metLixeiraColor(lixeira) {
      if (lixeira) return "orange darken-2";
      else return "red darken-1";
    },

    metParaLixeira(pfisica) {
      this.actLixeiraPFisica({
        pfisica: pfisica,
        licenca: this.$store.state.login.licenca,
        modificacao: this.$store.state.login.usuario.usuario,
      });
    },

    metEdicaoLixeira() {
      if (this.listaPFisPFisicas == "lixeira") {
        return false;
      }
      return true;
    },

    metVoltar() {
      this.$router.back();
    },

    metRecarregar() {
      this.actListarPFisicas(this.$store.state.login.licenca);
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
  },
};
</script>

<style></style>
